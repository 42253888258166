import { api } from '../../../utils/api';
import authTokenHeader from '../../../common/functions/authTokenHeader';

const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getUser: builder.query({
      query: () => {
        return {
          'url': '/user/myaccount/',
          'method': 'get',
          'header': {
            ...authTokenHeader(),
          }
        }
      },
      providesTags: ['fetchUser']
    }),
    loginAttempt: builder.mutation({
      query: ({ username, password }) => {
        return {
          'url': `/user/token/`,
          'method': 'post',
          'body': { username, password },
        }
      },
      invalidatesTags: ['HeaderNav', 'Jobs', 'purchasedPackages', 'jobStatus', 'profile', 'fetchUser']
    }),
    refreshLogin: builder.mutation({
      query: ({ refresh }) => {
        return {
          'url': '/user/token/refresh/',
          'method': 'post',
          'body': { refresh },
        }
      }
    }),
    verifyLogin: builder.mutation({
      query: () => {
        return {
          'url': '/user/token/validator/',
          'method': 'get',
          'headers': {
            ...authTokenHeader(),
          }
        }
      }
    }),
    signUp: builder.mutation({
      query: (body) => {
        return {
          'url': '/user/jobseeker/signup/',
          'method': 'post',
          'body': body
        }
      }
    }),
    forgotPassword: builder.mutation({
      query: (body) => {
        return {
          'url': '/user/forgot-password/',
          'method': 'post',
          'body': body
        }
      }
    }),
    existingUserLogin: builder.mutation({
      query: (body) => {
        return {
          'url': '/user/jobseeker/enable-site-login-access-email-verification/',
          'method': 'post',
          'body': body
        }
      }
    }),
  }),
  extraOptions: {
    includeCreds: true,
  }
})

export const {
  useLoginAttemptMutation,
  useRefreshLoginMutation,
  useVerifyLoginMutation,
  useLazyGetUserQuery,
  useSignUpMutation,
  useForgotPasswordMutation,
  useExistingUserLoginMutation
} = authApi
export const { endpoints: { loginAttempt, refreshLogin, verifyLogin } } = authApi