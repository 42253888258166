import React, { useState, useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { MdError } from "react-icons/md";
import { HiOutlineExternalLink } from "react-icons/hi";
import useLoginForm from '../hooks/useLoginForm'
import RoundLoader from '../../../components/Loader/RoundLoader'
import bannerImage from '../../../assets/images/generic-banner.png'
import styles from '../styles/Login.module.scss'
import ConfirmationPopup from '../../../components/ConfirmationPopup/ConfirmationPopup';
import { LgbtToast } from '../../../components/LgbtToast/LgbtToast'

function Login() {

    const [formError, setFormError] = useState('');
    const [unFocused, setUnFocused] = useState(null);
    const [pwfocused, setPwFocused] = useState(null);

    const loc = useLocation();
    const queryParams = new URLSearchParams(loc.search);
    const message = queryParams.get('message');

    const useloginFormMemoized = useCallback(useLoginForm, [setFormError])
    const {
        loginForm,
        isFetching,
        isLoading,
        extendedLoading,
        showConfirmPopup,
        setShowConfirmPopup,
        handleExistingUserLogin,
        popupQuestion,
    } = useloginFormMemoized(setFormError)
    const siteSetting = useSelector(state => state.site)


    let unErrorCls = loginForm.touched.username && loginForm.errors.username ? styles.inputError : null
    let pwErrorCls = loginForm.touched.password && loginForm.errors.password ? styles.inputError : null

    const handleFocus = (e) => {
        if (e.target.name === 'username') {
            setUnFocused(styles.inputFocus)
        }
        if (e.target.name === 'password') {
            setPwFocused(styles.inputFocus)
        }
    }
    const handleBlur = (e) => {
        if (e.target.name === 'username') {
            setUnFocused(null);
        }
        if (e.target.name === 'password') {
            setPwFocused(null);
        }
        setFormError('');
    }

    const handlePopupConfirm = () => {
        setShowConfirmPopup(false);
        handleExistingUserLogin(loginForm.values); // Call the existing user login mutation
    };

    const handlePopupCancel = () => {
        setShowConfirmPopup(false);
    };

    useEffect(() => {
        if (message !== null && message !== '') {
            setTimeout(() => {
                LgbtToast({ message, type: 'success' })
            }, 400)
        }
    }, [])

    return (
        <>
            <section className={styles.login} style={{ backgroundImage: `url(${siteSetting.bannerImage ?? bannerImage})` }}>
                <div className={`${styles.forOuter} container`}>
                    <div className={styles.formWrap}>
                        <h1>Sign In</h1>
                        <form action="" onSubmit={loginForm.handleSubmit}>
                            {formError && !(isFetching || isLoading) ? (
                                <div className={styles.textError}>{formError}</div>
                            ) : null}
                            <div className={`${styles.inputWarp} ${unErrorCls} ${unFocused}`}>
                                <input
                                    type="text"
                                    name="username"
                                    value={loginForm.values.username}
                                    onChange={loginForm.handleChange}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    id="username"
                                    placeholder="Username" />
                                <MdError className={styles.errorIcon} />
                            </div>
                            <div className={`${styles.inputWarp} ${styles.password} ${pwErrorCls} ${pwfocused}`}>
                                <input
                                    type="password"
                                    name="password"
                                    value={loginForm.values.password}
                                    onChange={loginForm.handleChange}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    id="password"
                                    placeholder="Password" />
                                <MdError className={styles.errorIcon} />
                            </div>
                            <Link className={styles.forgotPw} to="/forgot-password">Forgot Password?</Link>
                            <div className={styles.submitWrap}>
                                {
                                    (isFetching || isLoading || extendedLoading) ?
                                        <RoundLoader className={styles.loginLoader} /> :
                                        <button type="submit" className={styles.submitBtn}>Sign in</button>
                                }
                            </div>
                            <div>
                                <p className={styles.signUpLink}>If you are a JobSeeker? <Link to="/jobseeker-signup">Create Account <HiOutlineExternalLink /></Link></p>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
            {/* Render Confirmation Popup if showConfirmPopup is true */}
            {showConfirmPopup && (
                <ConfirmationPopup
                    message={popupQuestion ? popupQuestion : 'This account already exists. Do you want to log in as an existing user?'}
                    onConfirm={handlePopupConfirm}
                    onCancel={handlePopupCancel}
                />
            )}
        </>
    )
}

export default Login
