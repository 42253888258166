import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'

import useCompanyUrl from '../../../common/hooks/useCompanyUrl'
import ReCaptcha from '../../../components/ReCaptcha/ReCaptcha'
import Button from '../../../components/Button/Button'
import JobPerk from '../../../components/JobPerk/JobPerk'
import JobLocMap from '../../../components/JobLocMap/JobLocMap'
import JobContent from '../../../components/JobContent/JobContent'
import JobPerkFullData from '../../../components/JobPerkFullData/JobPerkFullData'
import { FaFileContract, FaMoneyBill } from "react-icons/fa6";

import { Link } from 'react-router-dom'
import {
    Location,
    Calendar,
    CalendarTime,
    Home,
    Money,
    BriefCase
} from '../../../components/SvgIcons/SvgIcons'
import { FaHourglassStart, FaHourglassEnd } from "react-icons/fa";
import { FaLocationDot, FaBriefcase } from "react-icons/fa6";


import bannerImage from '../../../assets/images/job_single_page_bg.svg'
import LgbtDecorLine from '../../../components/LgbtDecorLine/LgbtDecorLine'
import sticky_bg_right from '../../../assets/images/sticky_application_sidebg1.svg'
import sticky_bg_left from '../../../assets/images/sticky_application_sidebg2.svg'
import styles from '../styles/JobSinglePage.module.scss'
import { includes } from 'draft-js/lib/DefaultDraftBlockRenderMap'

function JobSingleTemplate({ data, recaptchaChangeHandler = null, jobApplyCountHandler = null, showRecaptcha = null }) {
    const companyUrl = useCompanyUrl()
    const siteSetting = useSelector(state => state.site)

    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [isFixed, setIsFixed] = useState(false);
    const [stickyStyles, setStickyStyles] = useState({});
    const descriptionRef = useRef(null);
    const stickyRef = useRef(null);
    const contentRef = useRef(null);
    useEffect(() => {
        function handleResize() {
            setIsMobile(window.innerWidth < 768);
        }



        function handleScroll() {

            if (stickyRef.current) {
                const stickyOffsetTop = stickyRef.current.getBoundingClientRect().top;
                const headerHeight = document.querySelector('header').offsetHeight;
                const contentBottom = contentRef.current.getBoundingClientRect().bottom;
                const footerHeight = document.querySelector('footer').offsetHeight;

                if (stickyOffsetTop <= headerHeight) {
                    setIsFixed(true);
                    if (contentBottom <= (footerHeight + 200)) {
                        setStickyStyles({
                            position: 'absolute',
                            top: `${contentRef.current.offsetHeight - document.querySelector('.stickyBtnSection').offsetHeight}px`,
                            width: stickyRef.current.offsetWidth + 'px',
                        });
                    } else {
                        setStickyStyles({});
                    }
                } else {
                    setIsFixed(false);
                }

            }
        }



        window.addEventListener('resize', handleResize);
        if (window.innerWidth >= 991) {
            window.addEventListener('scroll', handleScroll);
        }


        return () => {
            window.removeEventListener('resize', handleResize);
            if (window.innerWidth > 992) {
                window.addEventListener('scroll', handleScroll);
            }
        };
    }, []);
    function scrollToNext() {  // Ensure this function is defined correctly
        const headerHeight = document.querySelector('header').offsetHeight;
        const scrollToPosition = descriptionRef.current.getBoundingClientRect().top + window.pageYOffset - headerHeight;

        window.scrollTo({
            top: scrollToPosition,
            behavior: 'smooth'
        });
    }

    var jobSeekerType = ''
    if (siteSetting.domain.includes('neurodiversityjobs')) {
        jobSeekerType = 'Neurodivergent';
    }
    else if (siteSetting.domain.includes('bmejobs')) {
        jobSeekerType = 'BME';
    }
    else if (siteSetting.domain.includes('disabilityjob')) {
        jobSeekerType = 'Disability';
    }
    else {
        jobSeekerType = 'LGBT';
    }

    return (
        <div id="job-info" data-job-id={data?.id} className={`${styles.jobSinglePage} ${data?.status === 3 ? styles.expired : ''}`} >
            <section className={styles.banner} style={{ 'background-image': `url(${siteSetting.bannerImage ?? bannerImage})`, 'backgroundSize': jobSeekerType == 'BME' ? 'cover' : '2050px' }}>
                <div className="container">
                    <h1 className={styles.headline} dangerouslySetInnerHTML={{ __html: data?.title }}></h1>
                    <div className={styles.jobBlocks}>

                        <>
                            {(data?.employer_name === undefined || data?.employer_name === '') ? null : (
                                <JobPerk
                                    text={<>{data.employer_name}</>}
                                    redirectTo={companyUrl(data?.employer_slug)}
                                >
                                    <img src={data?.logo?.file} alt="" />
                                </JobPerk>
                            )}
                            {(data?.location?.length <= 0) ? null : (
                                <JobPerk text={<>{data?.location[0].raw_location_string}<i>{data?.location?.length > 1 ? ' and more' : ''}</i></>} title="Job Location">
                                    <FaLocationDot />
                                </JobPerk>
                            )}
                            {(data?.posted_date === undefined || data?.posted_date === '') ? null : (
                                <JobPerk text={<>{data.posted_date}</>} title="Job Posted">
                                    <FaHourglassStart />
                                </JobPerk>
                            )}
                            {(data?.expiry_date == undefined || data?.expiry_date === '' || data?.expiry_date === null || data?.hide_expiry_date === true || data?.hide_expiry_date === null) ? null : (
                                <JobPerk text={<>{data.expiry_date}</>} title="Job closing date">
                                    <FaHourglassEnd />
                                </JobPerk>
                            )}

                        </>

                    </div>
                    {
                        !isMobile ? (
                            <>
                                <Button className={styles.bannerBtn} onClick={scrollToNext}>Read More</Button>
                                {data?.status === 3 ? <h2 className={styles.expiredTitle}>This job has been expired</h2> : ''}
                            </>
                        ) : (
                            data?.status === 3 ? <h2 className={styles.expiredTitle}>This job has been expired</h2> : null
                        )
                    }
                </div>
            </section>

            <section className={styles.jobDetails} ref={descriptionRef}>
                <div className="container">
                    <div className={styles.ovwSec}>
                        {/* <h2 className={styles.headline}>Overview</h2> */}
                        <div className={styles.warpContents}>
                            <div className={`${styles.btnBlock}  ${isFixed ? styles.fixed : ''}`} ref={stickyRef}>
                                <div className={`${styles.stickyBtnSection} stickyBtnSection`} style={stickyStyles}>
                                    {
                                        jobSeekerType == 'LGBT' ?
                                            (<div className={styles.sideBarStripsLeft} >
                                                <img src={sticky_bg_left} alt='sticky banner left background' />
                                            </div>) :
                                            null
                                    }
                                    <h4 dangerouslySetInnerHTML={{ __html: data?.title }}></h4>
                                    <figure>
                                        <img src={data?.logo?.file} alt={data?.logo?.alternative_text} />
                                    </figure>

                                    <Button className={styles.jobBtn} to={data?.apply_link} onClick={jobApplyCountHandler} openInNewTab={true}>APPLY FOR JOB</Button>


                                    <Button className={styles.jobBtn + ' ' + styles.jobProfileBtn} to={companyUrl(data?.employer_slug)}>EMPLOYER PROFILE</Button>
                                    {
                                        jobSeekerType == 'LGBT' ?
                                            (<div className={styles.sideBarStripsRight} >
                                                <img src={sticky_bg_right} alt='sticky banner right background' />
                                            </div>) :
                                            null
                                    }
                                </div>
                            </div>
                            <div className={styles.contentBlock} ref={contentRef}>
                                <div className={styles.titleSec}>
                                    {
                                        data?.title == undefined ?
                                            null :
                                            (<div className={styles.jobTitle}>
                                                <h3 dangerouslySetInnerHTML={{ __html: data?.title }}></h3>
                                                <LgbtDecorLine />
                                            </div>)
                                    }
                                    {
                                        data?.location?.length <= 0 ?
                                            null :
                                            (<div className={styles.location}>
                                                <span>Location:</span>
                                                <strong>
                                                    {data?.location.map((location, index) => (
                                                        <span style={{ display: "block" }} key={index}>{location.raw_location_string}</span>
                                                    ))}
                                                </strong>

                                            </div>)
                                    }
                                    {
                                        data?.contract_types == undefined || data?.contract_types.length <= 0 ?
                                            null :
                                            (
                                                <div className={styles.contract_icon}>
                                                    <span>Job Type:</span>
                                                    <strong>{data?.contract_types.reduce((contractNames, contract) => (contractNames + ", " + contract))}</strong>
                                                </div>
                                            )
                                    }
                                    {
                                        data?.salary == undefined || data?.salary == '' || data?.salary.length <= 0 || data?.salary == "None" ?
                                            null :
                                            (
                                                <div className={styles.salary}>
                                                    <span>Pay:</span>
                                                    <strong>
                                                        {
                                                            data?.salary
                                                        }
                                                    </strong>
                                                </div>
                                            )
                                    }

                                </div>
                                <div className={styles.descriptionSec}>
                                    {data?.description == undefined ? null : <JobContent content={data.description} />}
                                </div>
                                <div className={styles.jobFooter}>
                                    {data?.apply_link == undefined ? null : <Button className={styles.footerBtn} to={data.apply_link} onClick={jobApplyCountHandler} openInNewTab={true}>APPLY FOR JOB</Button>}
                                    <p>If this job opportunity is not suitable, then check out the other <Link to='/find-a-job'>{jobSeekerType} friendly jobs</Link> across UK!</p>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>
                {/* Comanding the recaptcha code temporary*/}
                {/* {recaptchaChangeHandler != null && showRecaptcha ? <ReCaptcha recaptchaChangeHandler={recaptchaChangeHandler} /> : null} */}
            </section>
        </div>
    )
}
export default JobSingleTemplate
