import React from 'react';
import { useState } from "react";
import { useFormik } from 'formik'
import { useNavigate, useParams } from 'react-router-dom';
import { usePostJobMutation, useGetSkillsQuery } from '../api/jobCreateApiSlice'
import { useLocation } from 'react-router-dom';
import { useGetSingleJobQuery } from '../../JobSinglePage/api/singleJobApiSlice'

import * as Yup from 'yup'
import { LgbtToast } from '../../../components/LgbtToast/LgbtToast';
import useJobUrl from '../../../common/hooks/useJobUrl';

function useJobPostForm() {
    const loc = useLocation();
    const queryParams = new URLSearchParams(loc.search);
    const employer = parseInt(queryParams.get('employer'));

    const [createJob, { data: jobPostData, isJobFetching, isJobLoading, isJobError, jobError }] = usePostJobMutation();
    const jobUrl = useJobUrl();
    const navigate = useNavigate();

    const [errorMessage, setErrorMessage] = useState("");

    const initialValues = {
        title: "",
        description: "",
        salary_amount: 0,
        maximum_salary: 0,
        contract_types: [],
        location_strings: [],
        salary_pack: "",
        salary_type: 0,
        salary_text: "",
        sectors: [],
        apply_link: "",
        applyLinkType: 'url',
        show_on_lgbtjobscouk: true,
        show_on_disabilityjobcouk: true,
        show_on_bmejobscouk: true,
        show_on_neurodiversityjobscouk: true,
        is_remote_job: false,
        employer: employer,
        status: 1,
        skills: [],
        expiry_date: null,
    }

    const validationSchema = Yup.object({
        'title': Yup.string().required('Required').max(100, 'Max 100 characters'),
        'description': Yup.string().max(65535, 'Max 65535 characters'),
        'location_strings': Yup.array(),
        'applyLinkType': Yup.string(),
        'apply_link': Yup.string()
            .when('applyLinkType', {
                is: 'url',
                then: () => Yup.string().url('Please enter a valid URL').required('URL is required'),
                otherwise: () => Yup.string().email('Please enter a valid email address').required('Email is required'),
            }),
        'contract_types': Yup.array(),
        'skills': Yup.array()
            .of(Yup.mixed().test(
                'isValidValue',
                'Invalid Skill',
                value => typeof value === 'number' || typeof value === 'string'
            )),
        'salary_pack': Yup.string().optional(),
        'salary_amount': Yup.number().optional(),
        'maximum_salary': Yup.number().optional(),
        'salary_text': Yup.string().optional().nullable(),
        'salary_type': Yup.number().optional().nullable(),
        'expiry_date': Yup.date()
            .min(new Date(), 'Expiry date must be in the future').optional().nullable(),
        'sectors': Yup.array(),
        'show_on_lgbtjobscouk': Yup.boolean().optional(),
        'show_on_disabilityjobcouk': Yup.boolean().optional(),
        'show_on_bmejobscouk': Yup.boolean().optional(),
        'show_on_neurodiversityjobscouk': Yup.boolean().optional(),
        'is_remote_job': Yup.boolean().optional(),
        'employer': Yup.number(),
    })

    const jobPostForm = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values, bag) => {
            console.log("submit");
            // changing the date format to ISOString
            if (values.expiry_date instanceof Date)
                values.expiry_date = values.expiry_date.toISOString();

            values.salary = {
                title: values.salary_text,
                amount: values.salary_amount !== 0 ? parseFloat(values.salary_amount) || null : null,
                maximum_salary: values.maximum_salary !== 0 ? parseFloat(values.maximum_salary) || null : null,
                salary_type: values.salary_type
            }

            LgbtToast({ message: 'Posting job...', type: 'info' })
            let jobData = await createJob(values);
            if (jobData.error) {
                const Error = () => (<>Failed creating the job</>)
                setErrorMessage(jobData.error.data.detail);
                LgbtToast({ message: (<Error />), type: 'error' })
                return false;
            }
            const Success = () => (<>Job posted successfully! <a target='_blank' style={{ color: "#365BB8" }} href={jobUrl(jobData.data.slug)}>View</a></>)
            LgbtToast({ message: (<Success />), type: 'success' })
            setErrorMessage('')
            bag.resetForm();
            if (jobData.data.status === 5) {
                navigate(`/job/preview/?slug=${jobData.data.slug}&timestamp=${jobData.data.timestamp}`, { replace: true })
            } else {
                navigate('/dashboard', { replace: true })
            }
        }
    })

    return { jobPostForm, isJobFetching, isJobLoading, isJobError, jobError, errorMessage }
}

export default useJobPostForm