import React, { useState, useEffect, useRef } from 'react';
import styles from './ImageContentBlock.module.scss';
import useSelectTextColor from '../../../../common/hooks/useSelectTextColor';

function ImageContentBlock({ data }) {
    const [showFullContent, setShowFullContent] = useState(false);
    const contentLines = 15;
    const contentRef = useRef(null);
    const elementRef = useRef(null);
    const [isContentTruncated, setIsContentTruncated] = useState(false);

    useEffect(() => {
        const contentElement = contentRef.current;
        const content = elementRef.current;
        if (contentElement) {
            const lineHeight = parseInt(window.getComputedStyle(contentElement).lineHeight, 10);
            const maxHeight = lineHeight * contentLines;
            setIsContentTruncated(content.scrollHeight > maxHeight);
        }
    }, [contentLines, data.content]);

    const toggleContent = () => {
        setShowFullContent(!showFullContent);
    };
    const selectTextColor = useSelectTextColor();
    const sectionBgColor = data.hasOwnProperty('background_color') ? data.background_color : "#ffffff";
    const textColor = selectTextColor(sectionBgColor);
    const LinkTextColor = sectionBgColor == '#ffffff' ? "#365bb8" : textColor;
    const TitleTextColor = data.hasOwnProperty('title_text_color') ? data.title_text_color : "#000000"
    return (
        <div className={styles.imageContentWrap} style={{
            "--image_section_bg_color": sectionBgColor,
            "--image_section_text_color": textColor,
            "--link_text_color": LinkTextColor,
        }}>
            <div className="container">
                <div className={styles.imageContentBlock}>
                    {data.reverse ? (
                        <div className="row">
                            <div className={`${styles.imgBlock} col-md-6`}>
                                <img src={data.image_url} alt="" />
                            </div>
                            <div className={`${styles.contents} col-md-6`}>
                                <h2 style={{ color: TitleTextColor }}>{data.title}</h2>
                                <div
                                    ref={contentRef}
                                    style={{
                                        maxHeight: showFullContent ? 'none' : `${contentLines * 1.19}em`,
                                        overflow: 'hidden',
                                        transition: 'max-height 0.5s ease-out'
                                    }}
                                >
                                    <div ref={elementRef}
                                        style={{
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            display: '-webkit-box',
                                            WebkitLineClamp: showFullContent ? 'unset' : contentLines,
                                            WebkitBoxOrient: 'vertical',
                                        }}
                                        dangerouslySetInnerHTML={{ __html: data.content }}
                                    ></div>
                                </div>
                                {isContentTruncated && (
                                    <button onClick={toggleContent} className={`${styles.bttn} bttn`}>
                                        {showFullContent ? 'Read Less' : 'Read More'}
                                    </button>
                                )}
                            </div>
                        </div>
                    ) : (
                        <div className="row">
                            <div className={`${styles.contents} col-md-6`}>
                                <h2 style={{ color: TitleTextColor }}>{data.title}</h2>
                                <div
                                    ref={contentRef}
                                    style={{
                                        maxHeight: showFullContent ? 'none' : `${contentLines * 1.19}em`,
                                        overflow: 'hidden',
                                        transition: 'max-height 0.5s ease-out'
                                    }}
                                >
                                    <div ref={elementRef}
                                        style={{
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            display: '-webkit-box',
                                            WebkitLineClamp: showFullContent ? 'unset' : contentLines,
                                            WebkitBoxOrient: 'vertical',
                                        }}
                                        dangerouslySetInnerHTML={{ __html: data.content }}
                                    ></div>
                                </div>
                                {isContentTruncated && (
                                    <button onClick={toggleContent} className={`${styles.bttn} bttn`}>
                                        {showFullContent ? 'Read Less' : 'Read More'}
                                    </button>
                                )}
                            </div>
                            <div className={`${styles.imgBlock} col-md-6`}>
                                <img src={data.image_url} alt="" />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default ImageContentBlock;
