import { useState } from 'react';
import { useFormik } from 'formik'
import { useNavigate, useLocation } from "react-router-dom";
import * as Yup from 'yup'
import { useLoginAttemptMutation, useLazyGetUserQuery, useExistingUserLoginMutation } from '../api/authApiSlice';
import { login, setUser } from '../hooks/authSlice'
import { useDispatch, useSelector } from 'react-redux';
import { api } from '../../../utils/api';
import useRoleRoute from './useRoleRoute';

function useLoginForm(setFormError) {

    const [getUserData] = useLazyGetUserQuery();
    const auth = useSelector((state) => state.auth)

    const roleRoute = useRoleRoute()
    const navigate = useNavigate();
    const loc = useLocation();
    const queryParams = new URLSearchParams(loc.search);
    const username = queryParams.get('username');
    const password = queryParams.get('password');
    const location = useLocation();
    const dispatch = useDispatch();

    const [extendedLoading, setExtendedLoading] = useState(false);
    const [showConfirmPopup, setShowConfirmPopup] = useState(false);
    const [popupQuestion, setPopUpQuestion] = useState('');

    const initialValues = {
        username: username ?? '',
        password: password ?? ''
    }
    const validationSchema = Yup.object({
        'username': Yup.string().required('Required'),
        'password': Yup.string().required('Required'),
    })

    const [attemptLogin, { isFetching, isLoading }] = useLoginAttemptMutation()
    const [loginExistingUser, { isFetching: euFetching, isLoading: euLoading }] = useExistingUserLoginMutation()

    const handleExistingUserLogin = async (values) => {
        try {
            const response = await loginExistingUser(values);
            setExtendedLoading(true);
            if (response.error) {
                setFormError(response.error.data?.error || 'Something went wrong');
            } else {
                navigate('/jobseeker-login-verification', { replace: true });
            }
            setExtendedLoading(false);
        } catch (error) {
            console.error('Error during existing user login:', error);
        }
    };

    const loginForm = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            try {
                const data = await attemptLogin(values);
                if (data === undefined) {
                    setFormError('Something went wrong! Please try again later')
                    return;
                }
                if (data.error) {

                    if (data.error.status === 401 && data.error?.data.detail != undefined)
                        setFormError(data.error?.data.detail);
                    else if (data.error.status === 400 && data.error?.data.error != undefined)
                        setFormError(data.error?.data.error);
                    else if (data.error?.status === 403 && data.error.data?.warning){
                        setShowConfirmPopup(true);
                        setPopUpQuestion(data.error.data?.warning);
                    } else
                        setFormError('Something went wrong! Please try again later')
                    return;
                }
                dispatch(login(data.data))

                // fetching user data
                setExtendedLoading(true);
                const userData = await getUserData({}, true);


                if (userData.isError) {
                    setFormError('Failed to fetch user data');
                    navigate('/unauthorized', { replace: false })
                }

                dispatch(setUser(userData.data.user));

                loginForm.resetForm()
                setTimeout(() => {
                    dispatch(api.util.invalidateTags(['HeaderNav']))
                }, 300)

                setExtendedLoading(false);
                navigate(roleRoute(userData.data.user.role), { replace: true });
                // navigate(roleRoute(userData.data.user.role), { replace: true });

            } catch (error) {
                console.log('Something went wrong!', error);
            }
        }
    })


    return { loginForm, isFetching, isLoading, extendedLoading, showConfirmPopup, setShowConfirmPopup, handleExistingUserLogin, popupQuestion }
}

export default useLoginForm